import React from "react"
import { uid } from "uid"
import dynamic from "next/dynamic"

import SEOHead from "../src/components/layout/head"
import SchemaImage from "../src/components/schema/schema-image"
import HeroHalfInnerContent from "../src/components/hero/hero-half-inner-content"

import { getDataWorkWithUs } from "../graphql/work-with-us"

const ImageTextBlockHorizontal = dynamic(() =>
  import("../src/components/blocks/image-text-block-horizontal")
)
const SliderComponent = dynamic(() =>
  import("../src/components/blocks/slider-component")
)
const Shapes = dynamic(() => import("../src/components/bg-pattern/shapes"), {
  ssr: false,
})
const Patterns = dynamic(
  () => import("../src/components/bg-pattern/patterns"),
  { ssr: false }
)
const SliderComponentLargeImages = dynamic(() =>
  import("../src/components/blocks/slider-component-large-images")
)
const BlocksColumnContentCustom = dynamic(() =>
  import("../src/components/blocks/blocks-column-content-custom")
)
const AboutUsSlider = dynamic(() =>
  import("../src/components/sliders/about-us-slider")
)
const ModalComponent = dynamic(
  () => import("../src/components/modals/modal-component/modal-component"),
  { ssr: false }
)
const FindLocationBar = dynamic(
  () => import("../src/components/location/find-location-bar"),
  { ssr: false }
)

export const getStaticProps = async () => {
  let previewVar = false
  if (process.env.NEXT_PUBLIC_CF_PREVIEW) {
    previewVar = process.env.NEXT_PUBLIC_CF_PREVIEW
  }
  const workWithUs =
    (await getDataWorkWithUs({
      preview: previewVar,
      locale: `en-${process.env.NEXT_PUBLIC_COUNTRY.toUpperCase()}`,
    })) ?? []

  return {
    props: {
      page: workWithUs.data.page,
    },
    revalidate: 60,
  }
}

function WorkWithUsPage({ page }) {
  const slides = []

  return (
    <>
      <SEOHead
        title={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.seoTitle : null
        }
        description={
          page.items[0].seoMetadata
            ? page.items[0].seoMetadata.description
            : null
        }
        keywords={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.keywords : null
        }
        image={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.image : null
        }
        noindex={page.items[0].seoMetadata ? page.items[0].seoMetadata.noindex : null}
        nofollow={page.items[0].seoMetadata ? page.items[0].seoMetadata.nofollow : null}
      />

      {page.items[0].hero.cover && page.items[0].hero.cover.image || page.items[0].hero.cover && page.items[0].hero.cover.mobileImage  ? (
        <SchemaImage image={page.items[0].hero.cover.image || page.items[0].hero.cover.mobileImage} />
      ) : null}

      <React.StrictMode>
        <section className="overflow-hidden">
          <HeroHalfInnerContent
            image={
              page.items[0].hero.cover
                ? page.items[0].hero.cover
                : null
            }
            classHero="text-center bg-white"
            firstTitleTag="h1"
            firstTitle={
              page.items[0].hero.header ? page.items[0].hero.header : null
            }
            classFirstTitle="mb-4 lg:mb-0 leading-none"
            firstTitleBackground="bg-neon-red-400"
            firstTitleColor="text-white"
            firstTitleRotated
            secondTitleTag="h2"
            secondTitle={
              page.items[0].hero.subtitle ? page.items[0].hero.subtitle : null
            }
            classSecondTitle="mb-4 lg:mb-0 font-nandos text-3xl leading-none"
            secondTitleBackground=""
            secondTitleColor="text-black"
            secondTitleRotated={false}
            content={
              page.items[0].hero.content ? page.items[0].hero.content : null
            }
            buttons={
              page.items[0].hero.buttonsCollection
                ? page.items[0].hero.buttonsCollection.items
                : null
            }
            overlay={page.items[0].hero.enableOverlay}
            theme="light"
            alignContent="Center Mobile"
            isWorkWithUs="true"
            id={page.items[0].hero.videoId || null}
            source={page.items[0].hero.source || null}
            videoLoopId={page.items[0].hero.loopVideoId || null}
            videoLoopFile={
              page.items[0].hero.loopVideoFile
                ? page.items[0].hero.loopVideoFile.url
                : null
            }
            videoLoopSource={page.items[0].hero.loopVideoSource || null}
            popupPlayer={page.items[0].hero.popupPlayer}
            backgroundColor={page.items[0].hero.backgroundColor}
          />

          {page.items[0].blocks.items && (
            <>
              {page.items[0].blocks.items.map((block, index) => (
                <React.Fragment key={uid(16)}>
                  {block.blockType === "Promotions" && (
                    <>
                      {block.cover.image || block.cover.mobileImage ? (
                        <SchemaImage image={block.cover.image || block.cover.mobileImage} />
                      ) : null}
                      <ImageTextBlockHorizontal
                        blockWidthClasses="w-full"
                        content={block.description}
                        titleTag="h2"
                        title={block.name}
                        titleUnderlined="true"
                        wrapImage={index % 2}
                        lineWidth="w-90px"
                        titleClassList="font-nandos text-xxl2 mb-0"
                        cover={block.cover ? block.cover.image : null}
                        // CTA button will be hidden until the About US page is ready to go live
                        // buttons={block.cta}
                        coverPosition={block.coverPosition}
                        alignContent="Left"
                        widthDesktopImg={577}
                        heightDesktopImg={393}
                        widthMobileImg={335}
                        heightMobileImg={228}
                        layoutImgDesktop="fill"
                        layoutImgMobile="responsive"
                        paddingContent="lg:py-0 py-1"
                        contentWidthClasses="w-full lg:w-4/10 lg:pr-10 px-8 md:px-0 order-2 lg:order-1"
                        classContainer="container md:px-4 m-auto pt-12 lg:pt-28"
                        imageWidthClasses="w-full lg:w-6/10 order-1 md:order-1 lg:order-2 mb-15 lg:mb-0"
                        subtitleClasses="font-noto text-sm"
                        notHaveWrapper="true"
                        classList="lg:h-550px"
                        id={block.videoId || null}
                        source={block.source || null}
                        videoLoopId={block.loopVideoId || null}
                        videoLoopFile={
                          block.loopVideoFile ? block.loopVideoFile.url : null
                        }
                        videoLoopSource={block.loopVideoSource || null}
                        popupPlayer={block.popupPlayer}
                        backgroundColor={block.backgroundColor}
                      />
                    </>
                  )}

                  {block.blockType === "List" &&
                    (index % 2 ? (
                      <div className="relative overflow-hidden">
                        <BlocksColumnContentCustom
                          classList="bg-cream-100 text-center py-20 md:py-30 "
                          title={block.header}
                          titleTag="h2"
                          titleClassList="font-nandos text-xxl2 mb-2"
                          lineWidth="w-100px"
                          titleAlign="Center Desktop"
                          content={block.intro}
                          contentClasses="font-noto text-sm"
                          items={block.itemsCollection.items}
                          columnsClassList="w-full md:w-1/3 text-left lg:text-center mx-0"
                          containerClassList="max-w-900px"
                          contentAlign="Center Desktop"
                          itemsWrapperClassList="justify-around"
                          descriptionClassList="mb-15 "
                          subtitleClassList="font-nandos text-xxl2"
                          fontWeightColumns="font-noto"
                        />

                        <Patterns
                          background="flames-bg"
                          classList="hidden lg:block absolute lg:top-24 -left-28 w-80 text-metal-700 transform rotate-20"
                          classListColor="#ECE7DC"
                        />

                        <Patterns
                          background="heart"
                          classListColor="#ECE7DC"
                          classList="hidden lg:block absolute -right-20 bottom-10 h-80 w-80 transform rotate-125"
                        />
                      </div>
                    ) : (
                      <>
                        {block.itemsCollection.items.map(image => (
                          <SchemaImage image={image.imageIcon} key={uid(8)} />
                        ))}
                        <div className="relative">
                          <SliderComponent
                            imageItems={block.itemsCollection.items}
                            title={block.header}
                            layout="fill"
                            textClasslist="mb-0"
                          />
                          <Shapes classList="fill-current absolute right-0 bottom-0 text-orange-500 w-10 md:w-13 lg:w-13 transform rotate-0 -scale-x-1 -scale-y-1" />
                          <Shapes classList="fill-current absolute left-0 top-0 -inset-y-5 left-auto text-orange-700 w-13 md:w-10 lg:w-13 transform rotate-0" />
                        </div>
                      </>
                    ))}

                  {block.blockType === "TextImagesGrid" && (
                    <>
                      {block.imagesCollection.items.map(image => (
                        <SchemaImage image={image} key={uid(8)} />
                      ))}
                      <div className="relative">
                        <SliderComponentLargeImages
                          imageItems={block.imagesCollection.items}
                          title={block.header}
                          layout="fill"
                          textClasslist="mb-0 absolute bottom-7 text-white px-5 py-1 bg-black-middle text-xs"
                        />
                      </div>
                    </>
                  )}

                  {block.blockType === "ImageTextBlock" &&
                    (slides.push(block),
                    (
                      <ImageTextBlockHorizontal
                        blockWidthClasses="w-full"
                        classList={
                          slides.length === 1
                            ? "py-0 hidden -mt-2.5 lg:block pt-20"
                            : "py-0 hidden lg:block"
                        }
                        content={block.content}
                        title={block.header}
                        pattern={!index > 0}
                        patternClassList="text-metal-700 -bottom-28 lg:-bottom-52 top-auto -left-8 w-40 lg:w-64 transform -scale-x-1 rotate-2"
                        background="chillis"
                        titleTag="h2"
                        wrapImage={index % 2}
                        lineWidth="w-90px"
                        titleClassList="font-nandos text-xxl2 mb-0 lg:max-w-450px lg:text-left m-auto"
                        cover={block.cover}
                        buttons={block.cta.items}
                        coverPosition={block.coverPosition}
                        contentWidthClasses={
                          index > 0
                            ? "md:min-h-350px xl:min-h-450px w-full lg:w-1/2 px-4 pt-10 lg:px-0"
                            : "w-full lg:w-1/2 md:min-h-350px xl:min-h-450px "
                        }
                        imageWidthClasses="w-full lg:w-1/2"
                        classContainer=" "
                        classColumnsContent={
                          index > 0
                            ? "flex-col-reverse md:flex-row"
                            : "flex-row"
                        }
                        alignContent="Left"
                        widthDesktopImg={577}
                        heightDesktopImg={393}
                        widthMobileImg={335}
                        heightMobileImg={228}
                        layoutImgDesktop="fill"
                        layoutImgMobile="responsive"
                        imageClassList="object-bottom"
                        titleUnderlined="true"
                        subtitleClasses=" mt-0 mb-6 px-3 lg:px-0 md:font-noto text-sm lg:max-w-450px lg:text-left text-center m-auto"
                        headingClassList="max-w-450px text-left m-auto mb-8"
                        id={block.videoId || null}
                        source={block.source || null}
                        videoLoopId={block.loopVideoId || null}
                        videoLoopFile={
                          block.loopVideoFile ? block.loopVideoFile.url : null
                        }
                        videoLoopSource={block.loopVideoSource || null}
                        popupPlayer={block.popupPlayer}
                      />
                    ))}

                  {block.blockType === "HeroVideo" &&
                    (slides.push(block),
                    (
                      <ImageTextBlockHorizontal
                        blockWidthClasses="w-full"
                        classList={
                          page.items[0].blocks.items.length === index + 1
                            ? "py-0 hidden lg:block -mb-28"
                            : "py-0 hidden lg:block"
                        }
                        content={block.content}
                        title={block.header}
                        pattern={!index > 0}
                        patternClassList="text-metal-700 -bottom-28 lg:-bottom-52 top-auto -left-8 w-40 lg:w-64 transform -scale-x-1 rotate-2"
                        background="chillis"
                        titleTag="h2"
                        wrapImage={index % 2}
                        lineWidth="w-90px"
                        titleClassList="font-nandos text-xxl2 mb-0 lg:max-w-450px lg:text-left m-auto"
                        cover={
                          block.thumbnail &&
                          block.thumbnail.image &&
                          block.thumbnail.image
                            ? block.thumbnail.image
                            : ""
                        }
                        coverPosition={block.coverPosition}
                        buttons
                        contentWidthClasses={
                          index > 0
                            ? "md:min-h-350px xl:min-h-450px w-full lg:w-1/2 px-4 pt-10 lg:px-0"
                            : "w-full lg:w-1/2 md:min-h-350px xl:min-h-450px "
                        }
                        imageWidthClasses="w-full lg:w-1/2 z-1"
                        classContainer=" "
                        classColumnsContent={
                          index > 0
                            ? "flex-col-reverse lg:flex-row"
                            : "flex-row"
                        }
                        alignContent="Left"
                        widthDesktopImg={577}
                        heightDesktopImg={393}
                        widthMobileImg={335}
                        heightMobileImg={228}
                        layoutImgDesktop="fill"
                        layoutImgMobile="responsive"
                        imageClassList="object-bottom"
                        titleUnderlined="true"
                        subtitleClasses=" mt-0 mb-6 px-3 lg:px-0 md:font-noto text-sm lg:max-w-450px lg:text-left text-center m-auto"
                        headingClassList="max-w-450px text-left m-auto mb-8"
                        id={block.videoId || null}
                        source={block.source || null}
                        videoLoopId={block.loopVideoId || null}
                        videoLoopFile={
                          block.loopVideoFile ? block.loopVideoFile.url : null
                        }
                        videoLoopSource={block.loopVideoSource || null}
                        popupPlayer={block.popupPlayer}
                      />
                    ))}

                  {page.items[0].blocks.items.length === index + 1 ? (
                    <>
                      {slides.map(image => (
                        <SchemaImage image={image.thumbnail ? image.thumbnail.image : undefined || image.cover} key={uid(8)} />
                      ))}
                      <AboutUsSlider
                        havePadding="container lg:pl-20 lg:pr-20"
                        slides={slides}
                        printTitle="true"
                        classList="lg:hidden pt-10 -mt-2.5"
                      />
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}

          <FindLocationBar
            paddingTop="pt-10 lg:pt-20"
            classList="bg-cream-100"
          />
        </section>
        {page.items[0].popup ? (
          <>
            {page.items[0].popup ? (
              <SchemaImage image={page.items[0].popup}/>
            ) : null}
            <ModalComponent popup={page.items[0].popup} slug="/work" />
          </>
        ) : null}
      </React.StrictMode>
    </>
  )
}

export default WorkWithUsPage
